<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      :app="['xs', 'sm'].indexOf($vuetify.breakpoint.name) < 0"
      elevation="1"
      elevate-on-scroll
      height="80"
    >
      

      <!-- <base-img
        :src="require(`@/assets/zero-logo-${$vuetify.theme.isDark ? 'dark' : 'light'}.svg`)"
        contain
        max-width="96"
        width="100%"
      /> -->
      <h1 color="primary" @click="navHome()">
        <a href="/">
          <base-img
            :src="require('@/assets/logo-full.svg')"
            class="mr-3"
            contain
            max-width="350"
            width="100%"
            @click="navHome()"
          />
        </a>
      </h1>
      <h2
        class="
          ml-3
          mt-8
          blue-grey--text
          text-subtitle-2 text-uppercase
          hidden-sm-only hidden-xs-only
        "
      >
        Instant Discord Parties
      </h2>

      <v-spacer />
      <base-btn
        class="pa-3 hidden-xs-only"
        height="auto"
        outlined
        @click="openCommunityLink()"
      >
        Join Our Community
      </base-btn>

      <!-- <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="'#'+name"
            :exact="name === 'Home'"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      /> -->
    </v-app-bar>

    <home-drawer v-model="drawer" :items="items" />
  </div>
</template>

<script>
export default {
  name: "HomeAppBar",

  components: {
    HomeDrawer: () => import("./Drawer"),
  },

  data: () => ({
    drawer: null,
    items: [
      // 'PartyUp',
      // 'Instructions',
      // 'Features',
    ],
  }),
  methods: {
    navHome() {
      return this.$router.push("/");
    },
    showCTA() {
      return this.$vuetify.breakpoint.smOnly;
    },
    openCommunityLink: function (e) {
      window.open("https://discord.gg/WZ7EgH7w4d", "_blank");
    },
  },
};
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
